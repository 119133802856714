<template>
  <v-card>
    <v-card-actions>
      <v-btn color="primary" @click="imprimir()"><v-icon>print</v-icon></v-btn>
    </v-card-actions>
    <div id="nota_fiscal" class="danfe_pagina">
      <div class="danfe_pagina2">
        <table class="danfe_tabelas">
          <tr>
            <td>
              <table class="danfe_tabelas" style="min-height: 60px">
                <tr>
                  <td class="danfe_celula_bordas" colspan="2">
                    <p class="danfe_canhoto_texto">RECEBEMOS DE {{ nota.empresa.fantasia }} OS PRODUTOS CONSTANTES DA NOTA FISCAL INDICADA AO LADO</p>
                  </td>
                </tr>
                <tr>
                  <td class="danfe_canhoto_bordas">
                    <p class="danfe_celula_titulo">Data de recebimento</p>
                    <p class="danfe_celula_valor">&nbsp;</p>
                  </td>
                  <td class="danfe_canhoto_bordas">
                    <p class="danfe_celula_titulo">Identifica&ccedil;&atilde;o e assinatura do recebedor</p>
                    <p class="danfe_celula_valor">&nbsp;</p>
                  </td>
                </tr>
              </table>
            </td>
            <td>&nbsp;</td>
            <td>
              <table class="danfe_tabelas" style="min-height: 60px">
                <tr>
                  <td class="danfe_celula_bordas" align="center">
                    <strong>NF-e</strong>
                    <h2 class="danfe_cabecalho_numero">N&ordm; {{ formatador.formatarPadZero(nota.numero, 9) }}</h2>
                    <strong>S&eacute;rie {{ formatador.formatarPadZero(nota.serie, 3) }}</strong>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <div class="danfe_linha_tracejada"></div>
        <div style="width: 100%">
          <table class="danfe_tabelas">
            <tr>
              <td rowspan="3" class="danfe_celula_bordas" style="width: 15%; height: 100%">
                <img src="../../assets/nfe.jpg" style="width: 50%; height: 30%" />
              </td>
              <td rowspan="3" class="danfe_celula_bordas" style="width: 25%; height: 100%">
                <strong>{{ nota.empresa.razao }}</strong> <br />
                {{ nota.empresa.logradouro }}, {{ nota.empresa.numero }} <br />
                {{ nota.empresa.bairro }} - {{ nota.empresa.cidade }} - {{ nota.empresa.uf | codigoParaUf }} <br />
                Telefone - {{ formatador.formatarTelefone(nota.empresa.telefone) }} CEP {{ formatador.formatarCep(nota.empresa.cep) }}
              </td>
              <td rowspan="3" class="danfe_celula_bordas" align="center" style="width: 15%; height: 100%">
                <p class="danfe_cabecalho_danfe">DANFE</p>
                <p class="danfe_cabecalho_danfe_texto">Documento Auxiliar da <br />Nota Fiscal Eletr&ocirc;nica</p>
                <table class="danfe_tabelas">
                  <tr>
                    <td nowrap class="danfe_cabecalho_entrada_saida">
                      0-Entrada<br />
                      1-Sa&iacute;da
                    </td>
                    <td class="danfe_cabecalho_entrada_saida_quadrado">1</td>
                  </tr>
                </table>

                <p class="danfe_cabecalho_numero">N&ordm; {{ formatador.formatarPadZero(nota.numero, 9) }}</p>
                <p class="danfe_cabecalho_danfe_texto">SERIE: {{ formatador.formatarPadZero(nota.serie, 3) }}<br />P&aacute;gina: 1 de 1</p>
              </td>
              <td class="danfe_celula_bordas" align="center" style="width: 40%; height: 40%">
                <p class="danfe_celula_titulo">Controle do Fisco</p>
                <svg id="codigo_barras" style="width: 100%; height: 40%"></svg>
              </td>
            </tr>
            <tr>
              <td class="danfe_celula_bordas" align="center">
                <p class="danfe_celula_titulo">Chave de acesso</p>
                <p class="danfe_celula_valor">
                  <strong>{{ formatador.formatarChave(nota.chave) }}</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td class="danfe_celula_bordas" align="center">
                <p class="danfe_celula_titulo">Consulta de autenticidade no portal nacional da NF-e</p>
                <p class="danfe_celula_valor">
                  <a href="http://www.nfe.fazenda.gov.br/portal" target="_blank">www.nfe.fazenda.gov.br/portal</a>
                  ou no site da Sefaz autorizadora
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="danfe_celula_bordas">
                <p class="danfe_celula_titulo">Natureza da opera&ccedil;&atilde;o</p>
                <p class="danfe_celula_valor">{{ nota.natureza_operacao }}</p>
              </td>
              <td class="danfe_celula_bordas" align="center">
                <p class="danfe_celula_titulo">N&uacute;mero de protocolo de autoriza&ccedil;&atilde;o de uso da NF-e</p>
                <p class="danfe_celula_valor">
                  <strong v-if="!nota.autorizacao">NFe sem Autorização de Uso da SEFAZ</strong>
                  <strong v-else>{{ nota.autorizacao.protocolo }} {{ formatador.formatarData(nota.autorizacao.data) }}</strong>
                </p>
              </td>
            </tr>
          </table>
        </div>
        <table class="danfe_tabelas">
          <tr>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Inscri&ccedil;&atilde;o Estadual</p>
              <p class="danfe_celula_valor">{{ nota.empresa.ie }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Inscr.est. do subst.trib.</p>
              <p class="danfe_celula_valor">&nbsp;</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">CNPJ</p>
              <p class="danfe_celula_valor">{{ formatador.formatarCnpj(nota.empresa.cnpj) }}</p>
            </td>
          </tr>
        </table>

        <h3 class="danfe_titulo_externo">Destinat&aacute;rio/Remetente</h3>
        <table class="danfe_tabelas">
          <tr>
            <td>
              <table class="danfe_tabelas">
                <tr>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">Nome / Raz&atilde;o Social</p>
                    <p class="danfe_celula_valor">{{ nota.cliente.razaoSocialNome }}</p>
                  </td>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">CNPJ/CPF</p>
                    <p class="danfe_celula_valor">{{ formatador.formatarCnpj(nota.cliente.cnpjCpf) }}</p>
                  </td>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">Inscri&ccedil;&atilde;o Estadual</p>
                    <p class="danfe_celula_valor">{{ nota.cliente.inscricao }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">Endere&ccedil;o</p>
                    <p class="danfe_celula_valor">{{ nota.cliente.logradouro }}, {{ nota.cliente.numero }}</p>
                  </td>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">Bairro</p>
                    <p class="danfe_celula_valor">{{ nota.cliente.bairro }}</p>
                  </td>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">CEP</p>
                    <p class="danfe_celula_valor">{{ formatador.formatarCep(nota.cliente.cep) }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">Munic&iacute;pio</p>
                    <p class="danfe_celula_valor">{{ nota.cliente.cidade }}</p>
                  </td>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">Fone/Fax</p>
                    <p class="danfe_celula_valor">{{ formatador.formatarTelefone(nota.cliente.telefone) }}</p>
                  </td>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">UF</p>
                    <p class="danfe_celula_valor">{{ nota.cliente.estado }}</p>
                  </td>
                </tr>
              </table>
            </td>
            <td width="10%">
              <table class="danfe_tabelas">
                <tr>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">Data emiss&atilde;o</p>
                    <p class="danfe_celula_valor">{{ formatador.formatarData(nota.data_emissao) }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">Data sa&iacute;da</p>
                    <p class="danfe_celula_valor">{{ formatador.formatarData(nota.data_emissao) }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="danfe_celula_bordas">
                    <p class="danfe_celula_titulo">Hora sa&iacute;da</p>
                    <p class="danfe_celula_valor">{{ formatador.formatarHora(nota.data_emissao) }}</p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <h3 class="danfe_titulo_externo" v-if="nota.forma_pagamento !== '0'">Faturas</h3>
        <div style="border: 1px solid black; width: 100%; display: block; overflow: hidden" v-if="nota.forma_pagamento !== '0'">
          <div v-for="parcela in nota.parcelas_pagamento" :key="parcela.parcela" style="float: left; width: 20%; border-right: solid black 1px; border-bottom: solid black 1px">
            <table style="width: 100%">
              <tr align="left">
                <th class="danfe_item_cabecalho_fatura">N&uacute;mero</th>
                <th class="danfe_item_cabecalho_fatura">Vencimento</th>
                <th class="danfe_item_cabecalho_fatura" style="text-align: right">Valor</th>
              </tr>
              <tr class="danfe_item_fatura">
                <td align="left">{{ nota.numero }}-{{ parcela.parcela }}</td>
                <td align="left">{{ parcela.vencimento }}</td>
                <td style="text-align: right">{{ formatador.formatarValor(parcela.valor) }}</td>
              </tr>
            </table>
          </div>
        </div>
        <h3 class="danfe_titulo_externo">C&aacute;lculo do imposto</h3>
        <table class="danfe_tabelas">
          <tr>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Base de c&aacute;lculo do ICMS</p>
              <p class="danfe_celula_valor">{{ formatador.formatarValor(nota.total_bc_icms) }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Valor do ICMS</p>
              <p class="danfe_celula_valor">{{ formatador.formatarValor(nota.total_icms) }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Base de c&aacute;lculo do ICMS Subst.</p>
              <p class="danfe_celula_valor">{{ formatador.formatarValor(nota.total_bc_icms_st) }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Valor do ICMS Subst.</p>
              <p class="danfe_celula_valor">{{ formatador.formatarValor(nota.total_icms_st) }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Valor total dos produtos</p>
              <p class="danfe_celula_valor">{{ formatador.formatarValor(nota.total_produtos) }}</p>
            </td>
          </tr>
        </table>

        <table class="danfe_tabelas">
          <tr>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Valor do frete</p>
              <p class="danfe_celula_valor">{{ formatador.formatarValor(nota.frete) }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Valor do seguro</p>
              <p class="danfe_celula_valor">{{ formatador.formatarValor(nota.seguro) }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Desconto</p>
              <p class="danfe_celula_valor">{{ formatador.formatarValor(nota.desconto) }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Outras despesas acess&oacute;rias</p>
              <p class="danfe_celula_valor">{{ formatador.formatarValor(nota.outras_despesas) }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Valor do IPI</p>
              <p class="danfe_celula_valor">{{ formatador.formatarValor(nota.total_ipi) }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Valor total da nota</p>
              <p class="danfe_celula_valor">{{ formatador.formatarValor(nota.total_nota) }}</p>
            </td>
          </tr>
        </table>

        <h3 class="danfe_titulo_externo" v-if="nota.modelo_frete !== '9'">Transportador/Volumes transportados</h3>
        <table class="danfe_tabelas" v-if="nota.modelo_frete !== '9'">
          <tr>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Nome</p>
              <p class="danfe_celula_valor">{{ nota.transportador.razaoSocialNome }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Frete por conta</p>
              <p class="danfe_celula_valor">{{ formatador.formatarModeloFrete(nota.modelo_frete) }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">C&oacute;digo ANTT</p>
              <p class="danfe_celula_valor">{{ nota.transportador.codigo_ant }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Placa do ve&iacute;culo</p>
              <p class="danfe_celula_valor">{{ nota.transportador.placa }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">UF</p>
              <p class="danfe_celula_valor">{{ nota.transportador.uf }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">CNPJ/CPF</p>
              <p class="danfe_celula_valor">{{ formatador.formatarCnpj(nota.transportador.cnpjCpf) }}</p>
            </td>
          </tr>
        </table>

        <table class="danfe_tabelas" v-if="nota.modelo_frete !== '9'">
          <tr>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Endere&ccedil;o</p>
              <p class="danfe_celula_valor">{{ nota.transportador.logradouro }}, n&ordm; {{ nota.transportador.numero }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Munic&iacute;pio</p>
              <p class="danfe_celula_valor">{{ nota.transportador.cidade }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">UF</p>
              <p class="danfe_celula_valor">{{ nota.transportador.estado }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Inscri&ccedil;&atilde;o Estadual</p>
              <p class="danfe_celula_valor">{{ nota.transportador.inscricao_rg }}</p>
            </td>
          </tr>
        </table>

        <table class="danfe_tabelas" v-if="nota.modelo_frete !== '9'">
          <tr>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Quantidade</p>
              <p class="danfe_celula_valor">{{ nota.transportador.quantidade }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Esp&eacute;cie</p>
              <p class="danfe_celula_valor">{{ nota.transportador.especie }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Marca</p>
              <p class="danfe_celula_valor">{{ nota.transportador.marca }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Numera&ccedil;&atilde;o</p>
              <p class="danfe_celula_valor">{{ nota.transportador.numeracao }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Peso bruto</p>
              <p class="danfe_celula_valor">{{ nota.transportador.peso_bruto }}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Peso l&iacute;quido</p>
              <p class="danfe_celula_valor">{{ nota.transportador.peso_liquido }}</p>
            </td>
          </tr>
        </table>

        <h3 class="danfe_titulo_externo">Itens da nota fiscal</h3>
        <div style="border: 1px solid black; min-height: 250px">
          <table class="danfe_item_cabecalho_tabela">
            <thead>
              <tr>
                <th class="danfe_item_cabecalho">C&oacute;digo</th>
                <th class="danfe_item_cabecalho">Descri&ccedil;&atilde;o do produto/servi&ccedil;o</th>
                <th class="danfe_item_cabecalho">NCM/SH</th>
                <th class="danfe_item_cabecalho">CST</th>
                <th class="danfe_item_cabecalho">CFOP</th>
                <th class="danfe_item_cabecalho">UN</th>
                <th class="danfe_item_cabecalho">Qtde</th>
                <th class="danfe_item_cabecalho">Pre&ccedil;o un</th>
                <th class="danfe_item_cabecalho">Pre&ccedil;o total</th>
                <th class="danfe_item_cabecalho">BC ICMS</th>
                <th class="danfe_item_cabecalho">Vlr.ICMS</th>
                <th class="danfe_item_cabecalho">Vlr.IPI</th>
                <th class="danfe_item_cabecalho">%ICMS</th>
                <th class="danfe_item_cabecalho">%IPI</th>
              </tr>
            </thead>
            <tbody>
              <tr class="danfe_item" v-for="produto in nota.produtos" :key="produto.sequencia" style="border: 0px !important">
                <td align="left">{{ produto.codigo }}</td>
                <td align="left">{{ produto.descricao }}</td>
                <td>{{ produto.ncm }}</td>
                <td>{{ produto.cst_icms }}</td>
                <td>{{ produto.cfop }}</td>
                <td>{{ produto.unidade }}</td>
                <td>{{ formatador.formatarValor(produto.quantidade) }}</td>
                <td>{{ formatador.formatarValor(produto.valor) }}</td>
                <td>{{ formatador.formatarValor(produto.subtotal) }}</td>
                <td>{{ formatador.formatarValor(produto.bc_icms) }}</td>
                <td>{{ formatador.formatarValor(produto.valor_icms) }}</td>
                <td>{{ formatador.formatarValor(produto.valor_ipi) }}</td>
                <td>{{ formatador.formatarValor(produto.aliq_icms) }}</td>
                <td>{{ formatador.formatarValor(produto.aliq_ipi) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 class="danfe_titulo_externo">C&aacute;lculo do ISSQN</h3>
        <table class="danfe_tabelas">
          <tr>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Inscri&ccedil;&atilde;o Municipal</p>
              <p class="danfe_celula_valor"></p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Valor total dos servi&ccedil;os</p>
              <p class="danfe_celula_valor">0,00</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Base de c&aacute;lculo do ISSQN</p>
              <p class="danfe_celula_valor">0,00</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Valor do ISSQN</p>
              <p class="danfe_celula_valor">0,00</p>
            </td>
          </tr>
        </table>

        <div class="danfe_titulo_externo">Dados adicionais</div>
        <table class="danfe_tabelas">
          <tr style="min-height: 200px">
            <td class="danfe_celula_bordas" width="70%">
              <p class="danfe_celula_titulo">Observa&ccedil;&otilde;es</p>
              <p class="danfe_celula_valor">{OBSERVACOES}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Reservado ao fisco</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import JsBarcode from 'jsbarcode';
import moment from 'moment';
import conversor from '@/util/conversor';
import constantes from '@/util/constantes';
import formatador from '@/util/formatador';

export default {
  data: () => ({
    formatador: formatador,
    conversor: conversor,
  }),

  computed: {
    ...mapState('nota', {
      nota: 'notaAtual',
    }),
  },

  methods: {
    imprimir() {
      let nota = this.$store.state.nota.notaAtual;
      let htmlNota = document.getElementById('nota_fiscal').outerHTML;

      this.$store.dispatch('nota/imprimirNota', { html: window.btoa(unescape(encodeURIComponent(htmlNota))), chave: nota.chave });
    },

    gerarCodigo() {
      let nota = this.$store.state.nota.notaAtual;
      JsBarcode('#codigo_barras', nota.chave, { displayValue: false, height: 40 }); //format: 'CODE128'
    },
  },

  mounted() {
    this.gerarCodigo();
  },
};
</script>

<style>
.danfe_pagina {
  font-size: 10px;
  font-family: Arial, Helvetica;
  margin: 0px;
  padding: 1px;
}
.danfe_pagina2 {
  margin: 1px;
  padding: 0;
}
.danfe_linha_tracejada {
  width: 100%;
  border-bottom: #000 1px dashed;
  margin: 10px 0 10px 0;
}

.danfe_tabelas {
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 0;
}
.danfe_celula_bordas {
  border: 1px solid black;
  vertical-align: top;
}
.danfe_celula_titulo {
  margin: 0;
  font-size: 7pt;
  padding: 0 2px 0px 2px;
}
.danfe_celula_valor {
  margin: 0;
  font-size: 8pt;
  padding-left: 4px;
}

.danfe_canhoto_bordas {
  font-size: 7pt;
  border: 1px solid #000;
  margin: 0px;
  padding: 0;
  margin: 0 1px 0 1px;
  min-height: 30px;
}
.danfe_canhoto_texto {
  font-size: 6pt;
  margin: 0;
  font-weight: normal;
  padding: 0 2px 1px 2px;
}

.danfe_cabecalho_danfe {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}
.danfe_cabecalho_danfe_texto {
  font-size: 7pt;
  padding: 0;
  margin: 0 1px 0 1px;
  text-align: center;
}
.danfe_cabecalho_numero {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}
.danfe_cabecalho_entrada_saida {
  font-size: 7pt;
}
.danfe_cabecalho_entrada_saida_quadrado {
  font-size: 13pt;
  border: 1px solid #000000;
  padding: 0;
  margin: 0;
  width: 100px;
  text-align: center;
  float: none;
  min-width: 30px;
}

.danfe_titulo_externo {
  font-size: 8pt;
  margin: 4px 0 0 0;
  font-weight: bold;
}

.danfe_item {
  border: 1px black solid;
  border-top: none;
  border-bottom: dotted 1pt #dedede;
  font-size: 8pt;
  text-align: right;
}
.danfe_item_ultimo {
  border: 1px black solid;
  border-top: none;
  margin: 0px;
  padding: 0;
  font-size: 1px;
}
.danfe_item_cabecalho {
  border: 1px solid #000;
  text-align: left;
  font-size: 7pt;
}
.danfe_item_cabecalho_tabela {
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid #000;
}
.danfe_item_cabecalho_fatura {
  text-align: left;
  font-size: 7pt;
}
.danfe_item_fatura {
  border: 1px black solid;
  border-top: none;
  border-bottom: dotted 1pt #dedede;
  font-size: 8pt;
  text-align: right;
}
</style>
